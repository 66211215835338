import React, { useContext, useMemo } from 'react'

import { PricingTiers, Section } from '../../atoms'
import { GlobalState, GLOBAL_PARAMS, LISTINGS } from '../../../util'

import { StyledHeading } from './Pricing.style'

const Pricing = ({
  tiers,
  selectedTier,
  selectedListing,
  onSelect,
  ...renderProps
}) => {
  const { [GLOBAL_PARAMS.LISTING]: listingContext } = useContext(GlobalState)

  const listingName = useMemo(() => (
    LISTINGS[selectedListing || listingContext]?.name
  ), [selectedListing, listingContext])

  return (
    <Section wide {...renderProps}>
      <StyledHeading>
        {listingName}
      </StyledHeading>
      <PricingTiers
        tiers={tiers}
        selectedListing={selectedListing}
        selectedTier={selectedTier}
        onSelect={onSelect}
      />
    </Section>
  )
}

export default Pricing
