import React, { useState } from 'react'

import { getData } from '@agnostack/lib-core'
import { useAsyncMemo } from '@agnostack/lib-utils-react'
import { CaclulatorIcon } from '@agnostack/components-icons'

import { AppListings, Heading, Section } from '../../atoms'
import {
  AppsWrapper,
  IconWrapper,
  ButtonWrapper,
  CalculatorButton,
  StyledCalculator,
} from './AppsCalculator.style'

const AppsCalculator = ({
  id,
  mode,
  backdrop,
  format,
  children,
  selectedTier: _selectedTier,
  selectedListing,
  tiers: _tiers,
  onSelect,
  ...renderProps
} = {}) => {
  const [calculatorDisplayed, setCalculatorDisplayed] = useState(false)
  const [calculatorExpanded, setCalculatorExpanded] = useState(false)

  const tiers = useAsyncMemo(async () => (
    _tiers ?? getData('pricingData')
  ), [_tiers])

  return (
    <Section
      bare
      mode={mode}
      backdrop={backdrop}
      format={format}
      data-expanded={[calculatorDisplayed, calculatorExpanded]}
      {...renderProps}
    >
      <AppsWrapper>
        <Heading tag="4">
          {children}
        </Heading>
        <ButtonWrapper>
          <CalculatorButton onClick={() => setCalculatorDisplayed(!calculatorDisplayed)}>
            <IconWrapper>
              <CaclulatorIcon />
            </IconWrapper>
            <span>Savings Calculator</span>
          </CalculatorButton>
          <AppListings
            format={format}
            listing={selectedListing}
            onSelect={(listing) => onSelect({ listing })}
          />
        </ButtonWrapper>
      </AppsWrapper>
      {calculatorDisplayed && (
        <StyledCalculator
          selectedListing={selectedListing}
          tiers={tiers}
          format={backdrop || format}
          onExpand={setCalculatorExpanded}
          onSelect={(tier) => onSelect({ tier })}
          {...renderProps}
        />
      )}
    </Section>
  )
}

export default AppsCalculator
