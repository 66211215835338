import styled from 'styled-components'

import { Tag } from '@zendeskgarden/react-tags'

import { SavingsCalculator } from '../../atoms'

export const AppsWrapper = styled.div`
  padding: 1.5rem 0 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -2.2rem;
`

export const CalculatorButton = styled(Tag).attrs(({ theme }) => ({
  size: 'medium',
  hue: theme.formats.dark.background,
}))`
  && {
    cursor: pointer;
    user-select: none;
  }
  margin: 0 1rem 1rem;
`

export const IconWrapper = styled(Tag.Avatar)`
  padding-left: 4px;
`

export const StyledCalculator = styled(SavingsCalculator)`
  ${({ theme, format }) => `
    border: 1px solid ${theme.formats[format].background};
    border-bottom: none;
  `}
`
